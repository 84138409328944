import css from 'styled-jsx/css';

export default css`
  button {
    border: none;
    box-sizing: border-box;
    font-family: inherit;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
  }

  button:hover {
    outline: none;
  }

  button.btn-pay {
    background: #ff9f08;
    color: white;
  }

  button.btn-plus {
    background: var(--plus-color);
    color: white;
  }

  button.btn-enterprise {
    background: var(--enterprise-color);
    color: white;
  }

  button.btn-basic {
    background: var(--basic);
    color: white;
  }

  button.primary {
    background: var(--primary-button);
    color: var(--primary-button-a);
  }

  button.gradient-blue {
    background: var(--bg-gradient);
    color: var(--white);
  }

  button.reset-btn {
    border: solid thin var(--foreground);
    background: #ccc1;
    color: var(--foreground);
    font-weight: 400 !important;
  }

  button.reset-btn:hover {
    border: solid thin var(--foreground);
    background: #ccc3;
    color: var(--foreground);
  }

  button.primary:hover {
    box-shadow: var(--shadow-blue);
    background: var(--primary-button-hover);
  }

  button.gradient-blue :hover {
    box-shadow: var(--shadow-blue);
  }

  .button.btn-easy {
    background: #222b45;
    color: white;
  }

  button.shopify-btn {
    background: #008060;
    color: white;
  }

  button.shopify-underlined {
    background: none;
    color: #008060;
    text-decoration: underline;
  }

  button.underlined {
    text-decoration: underline;
    color: var(--underline-cta);
    background: none;
    font-size: 14px;
    font-weight: 400;
  }

  button.unsubscribe-btn {
    background: white;
    color: var(--grey-dark);
  }

  button.accent {
    background: var(--accent);
    color: var(--white);
  }

  button.accent:hover {
    box-shadow: var(--shadow-green);
    background: var(--accent-dark);
  }

  button.outlined {
    border: 1px solid var(--primary-button);
    background: transparent;
    color: var(--primary-button);
  }

  button.outlined:hover {
    background: var(--outlinedHover);
  }

  button.outlined.accent {
    border: 1px solid var(--accent);
    background: transparent;
    color: var(--accent);
  }

  button.outlined.accent:hover {
    color: var(--white);
    background: var(--accent);
  }

  button.outlined.white {
    border: 1px solid #ddd5;
    background: transparent;
    color: #ddd5;
  }

  button.outlined.white:hover {
    color: #fff;
    background: #ccc4;
  }

  button.outlined.error {
    border: 1px solid var(--error);
    background: transparent;
    color: var(--error);
    padding: 0px 4px;
  }

  button.outlined.error :global(.icon) {
    font-size: 13px;
  }

  button.outlined-submit {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: #f6f8fe;
  }

  button.large {
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 16px;
  }

  button.wide {
    width: 100%;
  }

  button.medium {
    height: 40px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    padding: 0px 16px;
  }

  button.small {
    height: 30px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
    padding: 0px 16px;
  }

  button.end-adornment {
    border-radius: 0 8px 8px 0;
    height: 50px;
  }

  button.primary:hover,
  button.gradient-blue:hover,
  button.pricing:hover,
  button.accent:hover {
    transform: translateY(-1px);
  }

  .simple {
    color: var(--primary);
    background: none;
    font-weight: 800;
  }

  .success {
    background: #37cc5c;
    color: var(--white);
  }

  .warning {
    background: #ffc014;
    color: var(--white);
  }

  .btn-white {
    background: #fff;
    color: var(--grey);
    border: solid thin var(--grey-lighter);
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .primary-trasparent {
    background: none;
    color: var(--primary);
    padding: 0.4em 1em;
    border-radius: 4px;
    font-size: 14px;
  }

  .primary-trasparent:hover {
    background: var(--li-sidenav-active);
  }

  .info {
    background: #33a9f7;
    color: var(--white);
  }

  .error {
    background: #ff4c4c;
    color: var(--white);
  }

  .social-icon svg {
    margin: 0px 8px;
    stroke: var(--grey-list);
    transform: scale(0.85);
  }

  .action {
    border: solid thin var(--grey-lighter);
    background: none;
    color: var(--foreground);
    font-weight: 600;
  }

  .action-full {
    border: solid thin var(--grey-lighter);
    background: var(--background-utils);
    color: var(--foreground);
    font-weight: 600;
  }

  .action-trasparent {
    background: none !important;
    border: none !important;
    color: var(--foreground);
    font-weight: 600;
    padding: 0px;
    margin: 0px;
  }

  .action-tag {
    background: #ccc1;
    border-radius: 55px !important;
    border: solid thin #6666;
  }

  .action-tag:hover {
    background: #ccc3;
    border-radius: 55px !important;
    border: solid thin #ccc;
  }

  .action:hover {
    background: var(--input-hover);
  }

  button:disabled,
  button[disabled],
  button.disabled {
    border: 1px solid var(--disabled);
    background: var(--disable-bg) !important;
    color: var(--disabled) !important;
    cursor: auto;
  }

  button:disabled:hover,
  button.disabled:hover {
    background: var(--disable-bg);
    color: var(--disabled);
    cursor: auto;
  }

  button.back {
    border: none;
    background: none;
  }

  button.back:hover {
    background: var(--input-hover);
  }

  .loading {
    cursor: wait;
    background: var(--button-loading);
    pointer-events: none;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .button.iconAfter :global(.icon) {
    margin: 0 0 0 8px;
  }

  .button.iconBefore :global(.icon) {
    margin: 0 8px 0 0;
  }

  .button.none {
    background: none;
  }

  .button.icon-action :global(.icon),
  .button.icon-action-dark :global(.icon) {
    margin: 0px;
  }

  .button.icon-action {
    background: none;
    color: var(--white);
    font-weight: 500;
    padding: 2px;
    border-radius: 5px;
    border: solid thin #fff9;
  }

  .button.icon-action:hover {
    background: #fff;
    border-radius: 5px;
    color: var(--grey);
    border: solid thin #fff4;
  }

  .button.icon-action-dark {
    background: none;
    font-weight: 500;
    padding: 2px;
    border-radius: 5px;
    border: solid thin var(--grey-lighter);
    background: var(--background-utils);
  }

  .button.icon-action-dark:hover {
    background: var(--import-action);
    color: var(--white);
    font-weight: 500;
    padding: 2px;
    border-radius: 5px;
  }

  .button.action-card {
    background: none;
    border-radius: 50px;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button.action-card :global(.icon) {
    margin: 0px;
  }

  button.focus {
    border: solid thin var(--primary);
    color: var(--primary);
    background: var(--background);
  }

  button.social-checkout {
    height: 100%;
    border-radius: 50px !important;
    background: linear-gradient(274.49deg, #ff006c 9.28%, #fdbd38 104.34%);
    color: white;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  button.social-button {
    color: white;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: linear-gradient(274.49deg, #ff006c 9.28%, #fdbd38 104.34%);
    //background: linear-gradient(to right, #84beff 0%, #b69cf6 60%);
    background: linear-gradient(to right, var(--accent) 0%, #09a5ec 100%);
    /*border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #84beff 0%, #b69cf6 60%) 2;
    transition: 0.3s;*/
  }

  button.social-button-2 {
    color: #09a5ec;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-utils);
    border: solid 1px #09a5ec;
    transition: 0.3s;
  }

  button.shop-button {
    background: #fb975f;
    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 6px;
  }

  button.cta-upgrade {
    border-radius: 12px;
    font-size: 14px;
    height: 45px;
    font-weight: 500;
  }

  button.black {
    background: var(--grey-dark);
    color: var(--white);
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
  }

  /*.button.social-button {
    background: #f88ee4;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    display: block;
  }*/

  .button.social-button:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
    transform: translateY(-1px);
  }

  button.social-button-2:hover {
    //  color: white;
    // font-size: 15px;
    // font-weight: 500;
    // letter-spacing: 0.6px;
    //display: flex;
    align-items: center;
    transform: translateY(-1px);
    justify-content: center;
    // background: linear-gradient(to right, #84beff 0%, #b69cf6 60%);
  }
`;
