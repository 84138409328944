import ButtonDropdown from 'components/utils/ButtonDropdown';
import useResponsive from 'hooks/useResponsive';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLocaleTranslation,
  setLocale,
  setTranslation,
} from 'redux/actions/translations';
import { LANGUAGES } from 'tools/constants';
import { setLocalStorage } from '../../tools/localStorage';
import { mergeTranslation } from '../../hooks/useTranslation';

function SelectLanguage({ inline }) {
  const [flag, setFlag] = useState();
  const dispatch = useDispatch();
  const savedLocale = useSelector((s) => s.locale);
  const { isMobile } = useResponsive();

  const changeLanguage = useCallback(
    (newLocale) => {
      dispatch(setLocale(newLocale));
      setLocalStorage(`cachedLocale`, newLocale);
      setFlag(LANGUAGES[newLocale].src);
      dispatch(
        getLocaleTranslation(
          newLocale,
          (translations) => {
            dispatch(setTranslation(mergeTranslation(translations || {})));
          },
          () => {}
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setFlag(LANGUAGES?.[savedLocale]?.src);
  }, [savedLocale]);

  return (
    <div className="select-language">
      {isMobile && inline ? (
        <div className="d-f select-lang-inline">
          <ul className="d-f g-20">
            {Object.keys(LANGUAGES).map((key) => (
              <li
                key={key}
                onClick={() => changeLanguage(key)}
                className={LANGUAGES[key].src === flag && 'selected'}
              >
                <img src={LANGUAGES[key].src} alt="language-flag" />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <ButtonDropdown img={flag}>
          <ul>
            {Object.keys(LANGUAGES).map((key) => (
              <li key={key} onClick={() => changeLanguage(key)}>
                <span>
                  <img src={LANGUAGES[key].src} alt="language-flag" />{' '}
                  {LANGUAGES[key].title}
                </span>
              </li>
            ))}
          </ul>
        </ButtonDropdown>
      )}
      <style jsx>{`
        .select-language {
          max-width: 200px;
        }
        .select-language :global(button img) {
          max-width: 24px;
        }
        .select-language :global(button) {
          width: 40px;
          margin: auto;
          background: none;
        }

        .select-language ul li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 8px 12px;
        }

        .select-language ul li:hover {
          background: #ccc3;
        }

        .select-language ul li span {
          color: var(--foregroung);
          font-weight: 500;
        }

        .select-language ul li span img {
          max-width: 24px;
          margin-right: 8px;
        }
        .select-lang-inline ul li {
          padding: 0px;
        }
        .select-lang-inline img {
          height: 25px;
          border: solid thin var(--background-utils);
          padding: 0.1em;
          border-radius: 4px;
          transition: 0.3s;
        }
        .selected {
          border: solid 2px var(--accent);
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
}

export default SelectLanguage;
